import { FormatUtils } from "@front/volcanion/utils"

class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        status: ['active', 'deleted', 'suspended'],
        ...filter
      }
    }
  }

  static formToFilterHandler() {
    return function formToFilter(values, extra, meta, state) {
      const { status, username } = values || {}
      return _.merge({},
        !!username && {
          or: [
            { info: { first_name: { contains: username } } },
            { info: { last_name: { contains: username } } },
            { auth: { username } }
          ]
        },
        !!status && {
          auth: FormatUtils.getParseFilterStatus(status),
        },
        { staffinfo: { '!': null } },
      )
    }
  }
}

export default Callbacks
